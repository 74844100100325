
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";

import Page from "@components/Blocks/Page";
import Loading from "@components/Blocks/Loading";
import Error from "@components/Blocks/Error";
import { FilterProvider } from "@components/Providers/FilterProvider";
import { DataContext } from "@components/Providers/DataProvider";

import "@style/Pages/Home.css";

const LoadableCollections = loadable(() => import("@components/Collection/Collections"), { fallback: <Loading /> })
const LoadableBanana = loadable(() => import("@components/Banana/Banana"), { fallback: <Loading /> })
const LoadablePNK = loadable(() => import("@components/PNK/PNK"), { fallback: <Loading /> })

const Home = () => {
    const { isError } = useContext(DataContext);
    return (
        <Page>
            <FilterProvider>
                {isError ?
                    <Error />
                    :
                    <Routes>
                        <Route path="/" element={ <LoadableCollections /> } />
                        <Route path="collections" element={ <LoadableCollections /> } />
                        <Route path="banana" element={ <LoadableBanana /> } />
                        <Route path="pnk" element={ <LoadablePNK /> } />
                    </Routes>
                }
            </FilterProvider>
        </Page>
    )
}

export default Home;