export const getQueryClassData = async (collection) => {
    let response;

    await fetch(`${process.env.REACT_APP_API_URL}/queries/${collection}/results/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.json())
    .then((data) => {
        response = JSON.parse(data.results)[0];
        response.updated_at = data.updated_at;
    });

    return response;
}