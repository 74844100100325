const SelectIcon = ({ className, src, ...props }) => {
    return (
        <div 
            className='select-icon-container'
            {...props}
        >
            <img 
                className={'select-icon'}
                src={src}
                alt={src}
            />
        </div>
    )
}

export default SelectIcon;