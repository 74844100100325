import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem} from '@mui/material';

import SelectIcon from "@components/Select/SelectIcon";

import "@style/Blocks/Navbar.css"

const Navbar = () => {
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleNavigate = (event, link) => {
        setAnchorEl(null);
        navigate(link);
    };

    const handleClose = (event, link) => {
        setAnchorEl(null);
    };

    return (
        <>
            <div id="navbar" className="page-margins">
                <nav>
                    <button className="banner-button" onClick={() => navigate("/")}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4354.625 1064.992">
                            <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
                                <g id="Group_165" data-name="Group 165">
                                <g id="Group_163" data-name="Group 163">
                                    <path id="Path_438" data-name="Path 438" d="M1142.251,939.492l-126-125.4h-20l-20-.1-96.7-96.7-96.7-96.7-6.7,5.2-6.7,5.2h-25.7v293.5l-13,15.5-13,15.5v74.7l-14.7,15.4-14.7,15.4-.8-.2c-.4-.2-8.2-7-17.3-15.1l-16.5-14.9.1-30.6c0-16.9.8-110.6,1.8-208.2l1.8-177.5-13.3-13.4-13.4-13.5v-275.2l12.5-14,12.5-13.9v-125.2l.7-13.6.6-13.7h38.3l11.1,12.7,11.2,12.8.1,158.5.1,158.5,12.9,10.9,13,10.9v35.9l1-.4c.5-.1,57.5-63.3,126.6-140.3s131.5-146.4,138.6-154.2l12.9-14.3h144.7l17.4,14c9.5,7.7,17.3,14.4,17.3,14.9s-5.6,8.2-12.5,17l-12.5,16-53,.2c-29.1.1-58.2.4-64.6.8l-11.7.6-28.6,32-28.5,32-.1,21.5-.1,21.5-73.9,80.4c-40.7,44.3-74,80.7-74,80.9,0,.4,67.2,68.1,185,186.5l51.5,51.7h38l96.3,96.3,96.2,96.2v79.5h-41.5l-126-125.5h0Z"/>
                                    <path id="Path_439" data-name="Path 439" d="M254.5,650.8l-17.8-16H53.6l-4.7,4-4.8,4H0V566.1L180.6,370.9,361.1,175.8h8.2c4.5,0,14-.3,21-.7l12.9-.6,56.9-60.6L517,53.3V28.2l16.6-13.9C542.7,6.6,550.5.2,550.9,0c.4-.1,6.7,5.8,13.9,13.2L578,26.6V264.1l-.1,31.2-16,14.5c-8.8,8-16.6,14.6-17.2,14.8l-1.2.2-13-14.7-13-14.7-.5-73.5-.5-73.5L314.3,361.7C203,479,112,575.4,112,575.9v.9H550V430.3l13.8-13.7L577.5,403l16.3,13.5L610,430.1V603.7l17,14.8,17.1,14.8-.3,1.2c-.2.6-6.5,8.1-14.1,16.7l-13.8,15.6H272.4l-17.9-16Z"/>
                                </g>
                                <g id="Group_164" data-name="Group 164">
                                    <path id="Path_440" data-name="Path 440" d="M1417.438,335.875l-84.072,84.072V676.8h68.185V564.522H1590.2V676.8h67.96V419.947l-84.072-84.072H1417.439Zm128.43,68.184,44.329,44.132v48.147H1401.551v-48.16l44.119-44.119Z"/>
                                    <path id="Path_441" data-name="Path 441" d="M1972.513,565.291,1790.349,335.875h-66.6V676.8h68.185V447.385L1974.1,676.8h66.6V335.875h-68.183Z"/>
                                    <path id="Path_442" data-name="Path 442" d="M2190.361,335.875l-84.072,84.072V676.8h68.185V564.522H2363.12V676.8h67.96V419.947l-84.072-84.072H2190.362Zm128.43,68.184,44.329,44.132v48.147H2174.474v-48.16l44.119-44.119Z"/>
                                    <path id="Path_443" data-name="Path 443" d="M2564.856,335.875h-68.184V676.8H2773.5V608.618H2564.856Z"/>
                                    <path id="Path_444" data-name="Path 444" d="M3015.472,390.509,2913.192,516.47,2810.913,390.509V335.875h-68.184v78.993l136.484,167.859V676.8h67.959V582.727l136.484-167.859V335.875h-68.184Z"/>
                                    <path id="Path_445" data-name="Path 445" d="M3149.247,404.058h112.28V676.8h68.184V404.058H3441.99V335.875H3149.247Z"/>
                                    <rect id="Rectangle_313" data-name="Rectangle 313" width="68.185" height="340.927" transform="translate(3507.583 335.875)"/>
                                    <path id="Path_446" data-name="Path 446" d="M3641.359,419.947V592.729l84.073,84.072H3966.15V584.637h-67.96v23.981H3753.663L3709.544,564.5V448.177l44.119-44.119H3898.19v23.981h67.96V335.875H3725.432Z"/>
                                    <path id="Path_447" data-name="Path 447" d="M4100.824,428.343l27.206-24.285h133.234l45.155,45.156,48.206-48.208-65.13-65.131H4101.788l-69.148,62.011v97.978l240.7,71.716v16.773l-27,24.265H4120.963l-45.154-45.155L4027.6,611.671l65.132,65.13h179.638l69.148-62.011V516.812L4100.824,445.1Z"/>
                                </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div className="nav-tabs">
                        <Link 
                            className={`nav-tab ${
                                (location === '/collections' || location=== "/") && 'active'
                            }`} 
                            to="/collections"
                        >
                            <h2>COLLECTIONS</h2>
                        </Link>
                        <Link 
                            className={`nav-tab ${location === '/pnk' && 'active'}`} 
                            to="/pnk"
                        >
                            <h2>PLAY & KOLLECT</h2>
                        </Link>
                        <Link 
                            className={`nav-tab ${location === '/banana' && 'active'}`} 
                            to="/banana"
                        >
                            <h2>BANANA</h2>
                        </Link>
                    </div>
                    <div className="nav-mobile-tabs">
                        <>
                            <Button
                                id="nav-burger"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{
                                    color: "#FF00E5"
                                }}
                            >
                                <svg viewBox="0 0 100 80" width="20" height="20" fill="#fff">
                                    <rect width="100" height="20"></rect>
                                    <rect y="30" width="100" height="20"></rect>
                                    <rect y="60" width="100" height="20"></rect>
                                </svg>
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem 
                                    onClick={(e) => handleNavigate(e, "/collections")}
                                    onTouchStart={(e) => handleNavigate(e, "/collections")}
                                    className={`${(location === '/collections' || location=== "/") && 'menu-active'}`}
                                >
                                    <SelectIcon src={"./combined.png"} alt={"collections"} />
                                    <span>Collections</span>
                                </MenuItem>
                                <MenuItem 
                                    onClick={(e) => handleNavigate(e, "/pnk")}
                                    onTouchStart={(e) => handleNavigate(e, "/pnk")}
                                    className={`${location === '/pnk' && 'menu-active'}`}
                                >
                                    <SelectIcon src={"./fuel_rod.png"} alt={"Fuel Rod"} />
                                    <span>Play & Kollect</span>
                                </MenuItem>
                                <MenuItem 
                                    onClick={(e) => handleNavigate(e, "/banana")}
                                    onTouchStart={(e) => handleNavigate(e, "/banana")}
                                    className={`${location === '/banana' && 'menu-active'}`}
                                >
                                    <SelectIcon 
                                        src={"./banana.png"} 
                                        alt={"banana"} 
                                    />
                                    <span>Banana</span>
                                </MenuItem>
                            </Menu>
                        </>
                    </div>
                </nav>
            </div>
            <div className="page-divider" />
        </>
    )
}

export default Navbar;