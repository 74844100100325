import { createContext, useContext, useState, useMemo } from "react";
import { DataContext } from "./DataProvider";

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [ collection, setCollection ] = useState("Genesis");
    const [ timeframe, setTimeframe ] = useState("Month");
    const [ chain, setChain ] = useState("Both");
    const [ season, setSeason ] = useState("Current");

    const { collectionData, pnkData, bananaData } = useContext(DataContext);

    const lastRefresh = useMemo(() => {
        const collectionDate = new Date(collectionData?.updated_at).getTime();
        const pnkDate = new Date(pnkData?.updated_at).getTime();
        const bananaDate = new Date(bananaData?.updated_at).getTime();
        const oldestDate = Math.min(collectionDate, pnkDate, bananaDate);

        const ymdDate = new Date(oldestDate).toLocaleString('en-GB').split(" GMT")[0].slice(0, -3);
        const time = ymdDate.split(',')[1]
        const day = ymdDate.slice(0, 2);
        const month = ymdDate.slice(3, 5);
        const year = ymdDate.slice(6, 10);

        if (isNaN(oldestDate)) return "";

        return `${year}-${month}-${day} ${time}`;
    }, [collectionData, pnkData, bananaData])
    
    return (
        <FilterContext.Provider value={{
            collection, setCollection,
            timeframe, setTimeframe,
            chain, setChain,
            season, setSeason,
            lastRefresh
        }}>
            {children}
        </FilterContext.Provider>
    )
}