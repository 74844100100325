import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "@components/Pages/Home";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Home />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;