import Flipside from "@images/flipsidewhite.svg";
import EXTERNAL_LINKS from "@components/Constants/EXTERNAL_LINKS";

import "@style/Blocks/Footer.css"

const Footer = () => {
    return (
        <div id="footer">
            <div className="footer-items created-by-block">
                    <div className="created-by">
                        <h5 style={{whiteSpace: "nowrap"}}>Created by&nbsp;</h5>
                        <a className="footer-link" href={EXTERNAL_LINKS.masonchain} target="_blank" rel="noreferrer">
                            <h5>@MasOnTheChain</h5>
                        </a>
                    </div>
                    <div className="footer-break">
                        <h5>&nbsp;and&nbsp;</h5>
                        <a className="footer-link" href={EXTERNAL_LINKS.banterlytics} target="_blank" rel="noreferrer">
                            <h5>@Banterlytics</h5>
                        </a>
                    </div>
            </div>
            <div style={{ width: "100%" }}> 
                <div className="footer-link">
                    <div style={{
                        display: "flex", 
                        height: "30px",
                        justifyContent: "center",
                    }}>
                        <h5 style={{height: "min-content", alignSelf: "center"}}>
                            Powered by
                        </h5>
                        <a 
                            href={EXTERNAL_LINKS.flipside} 
                            target="_blank" 
                            rel="noreferrer"
                            style={{ display: "contents" }}
                        >
                            <img 
                                src={Flipside} 
                                alt="Powered By Flipside Crypto" 
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;