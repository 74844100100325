import Footer from "./Footer";
import Navbar from "./Navbar";

import "@style/Blocks/Page.css";

const Page = ({children}) => {
    return (
        <div className="page-bg">
            <div style={{marginBottom: "40px"}}>
                <Navbar />
                
                <div className="page-margins top">
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Page;