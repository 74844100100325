const Error = () => {
    return (
        <div className="error" style={{
            width: "100%", 
            height: "50vh", 
            textAlign: "center",
            margin: "auto"
        }}>
            <h2
                style={{
                    marginTop: "30vh",
                    fontSize: "32px",
                    lineHeight: "1.5",
                }}
            >
                Our data feed is currently down for maintenance. Please check back shortly!
            </h2>
        </div>
    );
}

export default Error;