import CircularProgress from '@mui/material/CircularProgress';

import "@style/Blocks/Loading.css"

const Loading = () => {
    return (
        <div className="loading">
            <CircularProgress color="inherit" />
        </div>
    )
}

export default Loading;