import { Helmet } from "react-helmet-async";

import SEO_CONSTANTS from "@components/Constants/SEO_CONSTANTS";
import Router from "@components/Router/Router";
import { DataProvider } from "@components/Providers/DataProvider";

import './App.css';

function App() {
  return (
    <div id="app">
        <Helmet>
            <title>{SEO_CONSTANTS.title}</title>
            <meta name="title" content={SEO_CONSTANTS.title} />
            <meta name="og:title" content={SEO_CONSTANTS.title} />
            <meta name="twitter:title" content={SEO_CONSTANTS.title} />

            <meta name="description" content={SEO_CONSTANTS.description} />
            <meta name="og:description" content={SEO_CONSTANTS.description} />
            <meta name="twitter:description" content={SEO_CONSTANTS.description} />

            <meta property="og:url" content={`${window.location.href}`} />
            <meta property="og:type" content="website" />
        </Helmet>

        <DataProvider>
          <Router />
        </DataProvider>
    </div>
  );
}

export default App;
