import { createContext, useState, useEffect, useCallback } from "react";
import { getQueryClassData } from "@static/requests/all_requests";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [ collectionData, setCollectionData ] = useState();
    const [ pnkData, setPnkData ] = useState();
    const [ bananaData, setBananaData ] = useState();
    const [ isError, setIsError ] = useState(false);

    const updateData = useCallback(async () => {
        try {
            const [ collection, pnk, banana ] = await Promise.all([
                getQueryClassData("collection"),
                getQueryClassData("pnk"),
                getQueryClassData("banana")
            ]);
            
            setCollectionData(collection);
            setPnkData(pnk);
            setBananaData(banana);

            setIsError(false);
        } 
        catch (e) {
            console.error('Error fetching data', e);
            setIsError(true);
        }
    }, []);

    // console.log('All Data', {"Collection": collectionData, "PNK": pnkData, "Banana": bananaData})

    useEffect(() => {
        updateData();
    }, [updateData])

    return (
        <DataContext.Provider value={{
            collectionData,
            pnkData,
            bananaData,
            updateData,
            isError
        }}>
            {children}
        </DataContext.Provider>
    );
}